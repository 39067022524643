<template>
    <div class="px-3 md:px-0 pt-4">

        <div v-if="kategori != null">
            <div class=" bg-blue-500 rounded-md px-4 py-3">
                <div class="flex items-center">
                    <div class="text-white mr-2 cursor-pointer" @click="$router.go(-1)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                        </svg>
                    </div>
                    <div>
                        <h4 class="text-gray-100 font-semibold tracking-wide uppercase text-sm">Modul {{ $route.params.tipe_modul }}</h4>
                        <p class=" font-semibold tracking-tight text-white sm:text-2xl">
                            {{ kategori.judul }}
                        </p>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="paket != null" class="mt-6">
            <div class="grid grid-cols-1 md:grid-cols-2">
                <div >
                    <div class="bg-yellow-100 rounded-full w-40 h-40 mb-5 md:mb-0  md:w-72 md:h-72 flex items-center mx-auto">
                        <img src="@/assets/images/package.svg" class="w-1/2 mx-auto"/>
                    </div>
                </div>
                <div class="">
                    <h2 class="text-blue-700 font-semibold tracking-wider text-3xl">{{ paket.nama_paket }}</h2>

                    <div class="harga flex my-3" v-if="paket.status_akses == 1">
                        <span class="text-sm font-semibold">Rp.</span>
                        <div class="flex-grow">
                            <span v-if="paket.harga_coret != null" class="text-2xl font-bold tracking-wider text-red-500 mr-1"><s>{{ paket.harga_coret | moneyFormat }}</s></span>
                            <span class="text-3xl font-bold tracking-wider text-yellow-500">{{ paket.harga_paket | moneyFormat }}</span>
                        </div>
                    </div>
                    <p class="text-lg">{{ paket.deskripsi }}</p>
                </div>
            </div>

            <div class="bg-white shadow rounded-lg px-3 py-2 mt-2 mb-3">
                <p class="text-lg leading-8 font-semibold tracking-tight text-gray-900 capitalize">
                    Fitur Paket
                </p>
                <ul>
                    <li class="flex items-center" v-for="(data, index) in paket.info" :key="index">
                        <div class="h-2 w-2 bg-blue-200 rounded-full mr-2 flex-none"></div> 
                        <div class="flex-grow flex-wrap">{{ data }}</div>
                    </li>
                </ul>
            </div>
            

            <div class="bg-white shadow rounded-lg px-3 py-2 mt-2 mb-3" v-if="paket.persyaratan != ''">
                <p class="text-lg leading-8 font-semibold tracking-tight text-gray-900 capitalize">
                    Persyaratan
                </p>
                <p v-html="paket.persyaratan"></p>
            </div>

            <div @click="confirmKuisStart(data.kuis)" class="cursor-pointer flex items-center flex-row bg-white py-2 px-3 shadow rounded-lg my-2 transition-colors hover:border-gray-300"
                v-for="(data, index) in paket.kuis" :key="index">
                <div class="h-16 w-16 bg-blue-100 rounded-lg content-center flex items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-blue-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </div>

                <div class="flex-grow ml-3 pt-1 pb-1">
                    <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.kuis.judul_kuis }}</h2>
                    <span class="mr-5 text-gray-700 text-based">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg> 
                        {{ data.soal_count }} Jumlah Soal
                    </span>
                </div>
                <button class="px-4 h-10 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm hidden md:block">
                    Jawab Try Out
                </button>
            </div>


            <!-- apply button -->
            <div class="h-32 md:h-20"></div>

            <div  class="py-4 px-3 bg-white w-full fixed md:bottom-0 bottom-12 right-0 left-0 border-t-2 border-gray-100" v-if="paket.status_akses == 1 || paket.status_akses == 2 || paket.status_akses == 0 ">
                <div class="max-w-4xl mx-auto" >

                    <div v-if="paket.paket_user != null && paket.paket_user.status_register == 0">

                        <div class="flex items-center space-x-3" v-if="paket.status_akses == 1 && paket.paket_user.bukti_bayar == null">
                            <div class="relative flex-grow px-4 py-3 leading-normal text-yellow-700 bg-yellow-100 rounded-lg" role="alert">
                                <span class="absolute inset-y-0 left-0 flex items-center ml-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                </span>
                                <p class="ml-6 text-sm">Anda belum melakukan upload bukti pembayaran</p>
                            </div>
                            <router-link :to="{name: 'TagihanDetail', params : {'id_paket_user' : paket.paket_user.id_paket_user }}" class="group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
                                text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                                text-center ml-auto">Upload Bukti
                            </router-link>
                        </div>

                        <div class="relative px-4 py-3 leading-normal text-yellow-700 bg-yellow-100 rounded-lg" role="alert" v-else>
                            <span class="absolute inset-y-0 left-0 flex items-center ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </span>
                            <p class="ml-6 text-sm">Anda telah mendaftar ke modul ini namun masih tahap persetujuan admin</p>
                        </div>
                    </div>

                    <div v-else-if="paket.paket_user != null && paket.paket_user.status_register == 1">
                        <div class="relative px-4 py-3 leading-normal text-green-700 bg-green-100 rounded-lg" role="alert">
                            <span class="absolute inset-y-0 left-0 flex items-center ml-4">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                                </svg>
                            </span>
                            <p class="ml-6 text-sm">Pendaftaran anda telah dikonfirmasi oleh admin. Silahkan menjawab kuis</p>
                        </div>
                    </div>

                    <button v-else @click="modalJoin = true"
                        class="
                        group relative w-auto flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
                        text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        ml-auto
                    ">
                        <span class="flex items-center mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-100 group-hover:text-blue-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                            </svg>
                        </span>
                        {{ paket.status_akses == 1 ? "Beli Paket" : "Mendaftar" }}
                    </button>
                    
                </div>
            </div>
        </div>

        <div v-if="paket != null">
            <!-- Modal join gratis -->
            <modal :isOpen="modalJoin" v-if="paket.status_akses == 0" size="large">
                <div class="md:flex items-center" >
                    <div class="bg-blue-200 rounded-full flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-blue-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                        </svg>
                    </div>
                    <div class="mt-4 md:mt-0 md:ml-6 flex-grow">
                        <p class="font-bold">Medaftar ke Modul Gratis</p>
                        <span class="text-based font-semibold mt-3 text-gray-800">
                            Persyaratan
                        </span>
                        <p class="text-sm text-gray-700 mt-1 mb-3" v-html="paket.persyaratan"></p>

                        <div class="flex items-center">
                            <div class="bg-pink-600 rounded-full h-2 w-2 mr-2"></div>
                            <div class="text-sm text-gray-800">
                                Dibuka dari tanggal <span class="text-blue-500">{{ paket.min_tgl_daftar | toHumanDate3 }}</span> sampai <span class="text-blue-500">{{ paket.max_tgl_daftar | toHumanDate3 }}</span>
                            </div>
                        </div>
                        <div class="flex items-center">
                            <div class="bg-pink-600 rounded-full h-2 w-2 mr-2"></div>
                            <div class="text-sm text-gray-800">
                                Dibagi menjadi {{ paket.jumlah_sesi }} sesi ({{ paket.kuota_per_sesi }} orang per sesi)
                            </div>
                        </div>
                        <div class="form-target mt-3 border-2 rounded-lg px-3 py-2">
                            <div class="flex flex-col md:flex-row md:items-center my-4">
                                <label class="md:w-1/3">Pilih Sesi</label>
                                <div class="flex-grow relative inline-block w-full text-gray-700">
                                    <select v-model="mainForm.sesi_ke" class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="Regular input">
                                        <option selected disabled value="">Pilih salah satu</option>
                                        <option v-for="(sesi, index) in paket.info_sesi" :key="index" :value="index">Sesi {{ index }} ( {{sesi.mulai | toHumanDate3}} sampai {{ sesi.selesai | toHumanDate3 }} )</option>
                                    </select>
                                    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                                        <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                                    </div>
                                    <div class="text-xs text-red-700" v-if="errorForm.sesi_ke != '' ">{{ errorForm.sesi_ke }}</div>
                                    
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:items-center my-4">
                                <label class="w-1/3"></label>
                                <div class="flex-grow relative inline-block w-full text-gray-700">
                                    <div class="text-sm" v-if="mainForm.sesi_ke != '' ">
                                        Dimulai pada <span class="text-blue-500">{{ paket.info_sesi[mainForm.sesi_ke].mulai | toHumanDate3 }}</span> sampai <span class="text-blue-500">{{ paket.info_sesi[mainForm.sesi_ke].selesai | toHumanDate3 }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col md:flex-row md:items-center my-4">
                                <label class="md:w-1/3">Upload Persyaratan</label>
                                <div class="flex-wrap w-full">
                                    <multifile-picker :fileUpload="mainForm.filePersyaratan" />
                                    <div class="text-xs text-red-700" v-if="errorForm.file_persyaratan != '' ">{{ errorForm.file_persyaratan }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button @click="joinToModul()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                        <svg v-if="loadingModalJoin" class="animate-spin ml-1 mr-3 h-5 w-5 text-blue-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Mendaftar
                    </button>
                    <button @click="modalJoin = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
                </div>
            </modal>

            <!-- Modal join berbayar -->
            <modal :isOpen="modalJoin" v-if="paket.status_akses == 1" size="large">
                <div class="md:flex items-center" >
                    <div class="bg-purple-100 rounded-full flex items-center justify-center md:w-16 md:h-16 flex-shrink-0 mx-auto w-36 h-36">
                        <img src="@/assets/images/online-shopping.png" class="md:h-10 md:w-10 h-20 w-20 mx-auto"/>
                    </div>

                    <div class="mt-4 md:mt-0 md:ml-6 flex-grow pt-6">
                        <div class="text-center md:text-left">
                            <h2 class="uppercase text-gray-700 font-semibold">Total Tagihan</h2>
                            <div v-if="promo == null">
                                <h1 class="uppercase text-gray-900 text-3xl font-semibold">Rp. {{ paket.harga_paket | moneyFormat }}</h1>
                            </div>
                            <div v-if="promo != null">
                                <h1 class="uppercase text-gray-900 text-xl font-semibold inline-block">
                                    <s>Rp. {{ paket.harga_paket | moneyFormat }}</s>
                                    <strong class="text-green-600 text-3xl ml-2">{{ (  promo.jumlah_potongan > paket.harga_paket ? 0 : (paket.harga_paket - promo.jumlah_potongan)) | moneyFormat }}</strong>
                                </h1>
                            </div>
                        </div>


                        <div class="form-target mt-3 border-2 rounded-lg px-3 py-2">
                            <div class="flex items-center my-4 space-x-2">
                                <label class="w-1/3 hidden md:block">Kode Promo</label>
                                <div class="flex-wrap w-full">
                                    <custom-input id="kode_promo" label="Masukan Promo" v-model="mainForm.kode_promo" :errorMessage="errorForm.kode_promo"/>
                                </div>
                                <div @click="checkKodePromo()" class="px-3 p-3 bg-blue-600 text-white rounded-lg transition-all hover:bg-blue-700 cursor-pointer">
                                    <span v-if="isLoadingPromo == false">
                                        Gunakan
                                    </span>
                                    <span v-else>
                                        <svg class="animate-spin h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="form-target mt-3 border-2 rounded-lg px-3 py-2">
                            <p class="font-bold text-blue-600">Cara Bayar</p>
                            <div class="konten-html" v-html="konten.cara_bayar" />
                        </div>

                    </div>
                </div>
                <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button @click="joinToModul()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                        <svg v-if="loadingModalJoin" class="animate-spin ml-1 mr-3 h-5 w-5 text-blue-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Kirim Permintaan
                    </button>
                    <button @click="modalJoin = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
                </div>
            </modal>

            <!-- Modal join event -->
            <modal :isOpen="modalJoin" v-if="paket.status_akses == 2" size="large">
                <div class="md:flex items-center" >
                    <div class="bg-blue-100 rounded-full flex items-center justify-center md:w-16 md:h-16 flex-shrink-0 mx-auto w-36 h-36">
                        <img src="@/assets/images/calendar.svg" class="md:h-10 md:w-10 h-20 w-20 mx-auto"/>
                    </div>
                    <div class="mt-4 md:mt-0 md:ml-6  md:text-left flex-grow">
                        <div class="text-center">
                            <p class="font-bold">Ikut Event</p>
                            <p>Silahkan masukan kode event paket ini untuk bergabung!</p>
                        </div>

                        <div class="form-target mt-3 border-2 rounded-lg px-3 py-2">
                            <div class="flex flex-col md:flex-row md:items-center my-4">
                                <label class="md:w-1/3 hidden md:block">Kode Event</label>
                                <div class="flex-wrap w-full">
                                    <custom-input id="kode_event" label="Masukan kode event" v-model="mainForm.kode_event" :errorMessage="errorForm.kode_event"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button @click="joinToModul()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                        <svg v-if="loadingModalJoin" class="animate-spin ml-1 mr-3 h-5 w-5 text-blue-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Kirim Permintaan
                    </button>
                    <button @click="modalJoin = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
                </div>
            </modal>


            <!-- Modal start Kuis -->
            <modal :isOpen="modalKuisStart" size="large" v-if="active_kuis != null">
                <div class="md:flex items-center" >
                    <div class="bg-yellow-100 rounded-full flex items-center justify-center md:w-16 md:h-16 flex-shrink-0 mx-auto w-36 h-36">
                        <img src="@/assets/images/exam.svg" class="md:h-10 md:w-10 h-20 w-20 mx-auto"/>
                    </div>
                    <div class="mt-4 md:mt-0 md:ml-6 flex-grow">
                        <h2 class="text-3xl font-semibold tracking-tight mt-4">{{ active_kuis.judul_kuis }}</h2>
                        <p class="text-left">Instruksi Menjawab.</p>
                        <div class="border-2 rounded-lg bg-gray-50 px-4 py-3 mt-2" v-html="active_kuis.instruksi"></div>
                    </div>
                </div>
                <div class="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button @click="startQuiz()" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-blue-200 text-blue-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2">
                        Mulai Kuis
                    </button>
                    <button @click="modalKuisStart = false" class="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4 md:mt-0 md:order-1">Batal</button>
                </div>
            </modal>
        </div>

        <loading-modal :isShow="isLoadingStartKuis || isLoading || isLoadingModul" />
   </div>
</template>
<script>
    import CustomInput from '../../components/CustomInput.vue';
    import LoadingModal from '../../components/LoadingModal.vue';
    import Modal from '../../components/Modal.vue';
    import MultifilePicker from '../../components/MultifilePicker.vue';
    import { showErrorNotif, showSuccessNotif, extractValidationError, errorHandler, showWarningNotif } from '../../helpers/Tools';
    export default {
        name: 'DetailModul',
        components : {
            Modal, LoadingModal,
            CustomInput,
            MultifilePicker
        },
        computed: {
            konten () {
                return this.$store.getters.getKonten
            }
        },
        data() {
            return {
                isLoading     : true,
                isLoadingPromo: false,
                isLoadingModul: true,
                kategori      : null,
                paket         : null,

                modalJoin       : false,
                loadingModalJoin: false,

                isLoadingStartKuis : false,

                active_kuis   : null,
                modalKuisStart: false,

                isExpandCaraBayar : false,

                promo : null,
                mainForm : {
                    sesi_ke          : "",
                    nama_rekening    : '',
                    nama_bank        : '',
                    kode_event       : '',
                    filePersyaratan  : [],
                    fileBuktiTransfer: null,
                    kode_promo       : '',
                },
                errorForm : {
                    sesi_ke : "",
                    file_persyaratan : "",

                    nama_rekening   : '',
                    nama_bank       : '',
                    file_bukti_bayar: '',
                    kode_promo      : '',
                    kode_event      : ''
                }
            }
        },
        mounted() {
			this.getKategoriDetail();
		},
		methods : {
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data;
                        this.getModulDetail();
                    } 
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            async getModulDetail() {
                this.isLoadingModul = true;

                try {
                    let res = await this.$store.dispatch("getModulDetail",
                        this.$route.params.id_paket
                    );
                    this.isLoadingModul = false;
                    let result = res.data;
                    this.paket = result.data;

                } catch (error) {
                    this.isLoadingModul = false;
                    errorHandler(this, error);
                }
                
            },

            /**
             * Khusus berbayar dan event
             */
            async joinToModul() {
                this.loadingModalJoin = true;
                try {

                    let formData = new FormData();

                    // gratis, upload file
                    if(this.paket.status_akses == 0) { 

                        for(var i = 0; i < this.mainForm.filePersyaratan.length; i++) {
                            formData.append(`file_persyaratan[${i}]`, this.mainForm.filePersyaratan[i]);
                        }
                        formData.append('sesi_ke', this.mainForm.sesi_ke);
                    }

                    // event, input kode event
                    if(this.paket.status_akses == 2) { 
                        formData.append('kode_event', this.mainForm.kode_event);
                    }

                    if(this.paket.status_akses == 1) {
                        formData.append('kode_promo', this.mainForm.kode_promo);
                    }

                    let res = await this.$store.dispatch("joinToModul", {
                        id_paket : this.$route.params.id_paket,
                        formData : formData
                    });
                    this.loadingModalJoin = false;
                    let result = res.data;
                    if(result.status == 'success') {
                        showSuccessNotif(this, result.message);
                        this.modalJoin = false;
                        
                        if(this.paket.status_akses == 1 && result.data.status_register == 0) {
                            this.$router.push({ name: 'TagihanDetail', params: { id_paket_user: result.data.id_paket_user } })
                        } else {
                            this.getModulDetail();
                        }

                    } else {
                        showErrorNotif(this, result.message);
                        this.errorForm = extractValidationError(this.errorForm, result.data);
                    }
                } catch (error) {
                    this.loadingModalJoin = false;
                    errorHandler(this, error);
                }
            },

            confirmKuisStart(kuis) {
                this.active_kuis = kuis;
                this.modalKuisStart = true;
            },

            /**
             * Memulai kuis
             */
            async startQuiz () {
                var id_kuis = this.active_kuis.id_kuis;
                if(this.paket.status_akses == 3 || ( this.paket.paket_user != null && this.paket.paket_user.status_register == 1)) {

                    this.isLoadingStartKuis = true;

                    try {
                        let paket_user = this.paket.paket_user;
                        
                        // kondisi belum join, untuk paket tryout
                        if(paket_user == null) {
                            // join ke paket 
                            let res = await this.$store.dispatch("joinToModul", {
                                id_paket : this.$route.params.id_paket
                            });
                            let result = res.data;
                            if(result.status == 'success') {
                                paket_user = result.data;
                            } else {
                                showErrorNotif(this, result.message);
                                this.isLoadingStartKuis = false;
                            }
                        }

                        if(paket_user != null) {
                            // start kuis
                            let res = await this.$store.dispatch("kuisStart", {
                                id_paket     : this.$route.params.id_paket,
                                id_paket_user: paket_user.id_paket_user,
                                id_kuis      : id_kuis
                            });
                            let result = res.data;
                            this.isLoadingStartKuis = false;
                            if(result.status == 'success') {
                                showSuccessNotif(this, result.message);
                                this.$router.push({ name: 'JawabKuis', params: { token: result.data.token } })
                            } else {
                                showErrorNotif(this, result.message);
                            }
                        } 

                    } catch (error) {
                        this.isLoadingStartKuis = false;
                        errorHandler(this, error);
                    }
                } else {
                    showWarningNotif(this, "Anda belum dapat menjawab kuis ini");
                }
            },

            async checkKodePromo() {
                if(this.mainForm.kode_promo == '') {
                    showWarningNotif(this, "Silahkan masukan kode promo terlebih dahulu");
                } else {
                    this.isLoadingPromo = true;
                    try {

                        let res = await this.$store.dispatch("checkKodePromo", {
                            id_paket  : this.$route.params.id_paket,
                            kode_promo: this.mainForm.kode_promo
                        });
                        let result = res.data
                        this.isLoadingPromo = false
                        if(result.status == 'success') {
                            showSuccessNotif(this, result.message);
                            this.promo = result.data;
                        } else {
                            showErrorNotif(this, result.message);
                            this.promo = null;
                        }
                    } catch (error) {
                        this.isLoadingPromo = false;
                        errorHandler(this, error);
                    }
                }
            }

        }
    }
</script>
