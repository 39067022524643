<template>
    <div class="multi-file-picker flex w-full flex-wrap">
        
        <div class="w-40 h-24 relative md:w-40 md:h-24 m-1 rounded shadow items-center" v-for="(file, index) in fileUpload" :key="index" >
            <button @click="removeItem(index)" class="bg-red-600 p-1 rounded-full absolute -top-1 -right-1">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <img :src="objectUrl(file)" class="w-full h-full rounded" />
        </div>
        <label class="w-40 h-24 md:w-40 md:h-24 p-3 m-1 rounded file-picker text-center border-dashed border-2 items-center flex text-gray-400 hover:text-gray-700 transition-all cursor-pointer hover:border-gray-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-10 md:w-10 h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <input type="file" name="fileSelect" class="hidden" @change="onFileChange" />
        </label>

    </div>
</template>
<script>
export default {
    name: 'MultifilePicker',
    props: {
        fileUpload : {
            type   : Array,
            default:()=>[]
        }
    },
    methods: {
        updateValue (value) {
            this.$emit('input', value)
        },
        objectUrl(file) {
            return URL.createObjectURL(file)
        },
        onFileChange (e) {
            const file = e.target.files[0];
            this.fileUpload.push(file);
        },
        removeItem (index) {
            this.fileUpload.splice(index, 1);
        }
    }
}
</script>
